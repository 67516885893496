import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { akitaDevtools } from '@datorama/akita';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LiveService } from 'src/app/core/services/live.service';
import { GeoAccessControlService } from 'src/app/core/services/geo-access-control.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccumulatorBonusService } from 'src/app/core/services/bonuses/accumulator-bonus.service';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { CouponReceiptService } from 'src/app/core/services/coupon/coupon-receipt.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { DynamicScriptLoaderService } from 'src/app/core/services/dynamic-script-loader.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { GeolocationRedirectService } from 'src/app/core/services/geolocation-redirect.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ProductTypeService } from 'src/app/core/services/product-type.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { SportsOddsBoostService } from 'src/app/core/services/sports/sports-odds-boost.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { MetaService } from 'src/app/modules/meta/meta.service';
import { InitializeMobilePluginsService } from 'src/app/modules/native-app/services/initialize-mobile-plugins.service';
import { AndroidSettingsQuery } from 'src/app/modules/native-app/state/android-settings.query';
import { DataLayerEvent } from 'src/app/shared/models/datalayer.model';
import { environment } from 'src/environments/environment';
import { VirtualsCouponService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon.service';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { HeaderService } from 'src/app/modules/header/services/header.service';
import { ElasticService } from 'src/app/core/services/elastic.service';
import { MetaDataQuery } from 'src/app/modules/meta/store/meta-data.query';
import { AppConfigService } from './core/services/app-config.service';
import { InstantCouponService } from './core/services/instant-coupon/instant-coupon.service';
import { LanguageService } from './core/services/language.service';
import { ShopOwnerService } from './core/services/shop-owner.service';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'mobile';
  sfkEnabled = this.appConfig.get('liveChat').liveChatIntegration === 'sfk';
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    readonly androidSettingsQuery: AndroidSettingsQuery,
    readonly applicationQuery: ApplicationQuery,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly accumulatorBonusService: AccumulatorBonusService,
    private readonly appConfig: AppConfigService,
    private readonly elasticService: ElasticService,
    private readonly applicationService: ApplicationService,
    private readonly applicationStore: ApplicationStore,
    private readonly cashoutService: CashoutService,
    private readonly cookieService: CookieService,
    private readonly couponReceiptService: CouponReceiptService,
    private readonly couponService: CouponService,
    private readonly dataLayerService: DataLayerService,
    private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
    private readonly evaluationService: EvaluationService,
    private readonly geoAccessControlService: GeoAccessControlService,
    private readonly geolocationRedirectService: GeolocationRedirectService,
    private readonly headerService: HeaderService,
    private readonly initializeMobilePlugins: InitializeMobilePluginsService,
    private readonly instantCouponService: InstantCouponService,
    private readonly languageService: LanguageService,
    private readonly liveService: LiveService,
    private readonly loadingService: LoadingService,
    private readonly metaService: MetaService,
    private readonly ngZone: NgZone,
    private readonly productTypeService: ProductTypeService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
    private readonly shopOwnerService: ShopOwnerService,
    private readonly sportsOddsBoostService: SportsOddsBoostService,
    private readonly virtualsCouponService: VirtualsCouponService,
    private readonly metaDataQuery: MetaDataQuery
  ) {
    if (!environment.production) {
      akitaDevtools(ngZone);
    }
  }

  ngOnInit(): void {
    this.initialise();
    if (environment.production) {
      this.metaService.setCanonicalURL(this.appConfig.get('canonicalUrl'));
    }
    const cacheTTL = this.appConfig.get('cmsCacheTTL');
    const fallbackTimer = this.appConfig.get('fallbackTimer');
    this.applicationService.updateCms({ cacheTTL, fallbackTimer });

    this.attachPrefetchLinks();
    this.userStatusCheck();
    this.createDataLayerEvent();
    this.setAffiliateCookieByConfig();
    this.setApmUserContext();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private userStatusCheck(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.accountQuery.userData) {
          switch (this.accountQuery.userData.userStatus) {
            case 'PCHANG': // Password changed
              if (event.url !== '/account/password-reset') {
                this.router.navigateByUrl('account/password-reset');
              }
              break;
            case 'UNCONF': // Account unconfirmed
              if (
                this.applicationQuery.enableOTPBySMS &&
                !this.appConfig.get('otp').allowUnconfirmedUsers &&
                event.url !== '/account/phone-number-verification/true'
              ) {
                if (this.registrationQuery.activationOption !== 'none') {
                  if (this.registrationQuery.activationOption === 'BySms') {
                    this.router.navigateByUrl('account/phone-number-verification/true');
                  }
                } else {
                  this.registrationService.getActivationOption().subscribe(() => {
                    if (this.registrationQuery.activationOption === 'BySms') {
                      this.router.navigateByUrl('account/phone-number-verification/true');
                    }
                  });
                }
              }
              break;
            default:
              break;
          }
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url.indexOf('phone-number-verification') > -1) {
          this.accountService.updateShowUnverifiedTooltip(false);
        } else {
          this.accountService.updateShowUnverifiedTooltip(true);
        }
      }
    });
  }

  @HostListener('window:message', ['$event'])
  private onMessage(event): void {
    /**
     * With work done in task VIR-8148, the mobile site can now be embedded in external sites through the use of an iframe.
     * We're making use of the 'Window.postMessage()' API to enable cross-communication between the mobile site and the embedding site.
     * The following message types are being used to drive this communication:
     * - bv.embedConfig: The embedding site uses this message to pass the embedConfig object and enable the embedding functionality
     * - bv.embedEvent: The mobile site uses these messages to notify the embedding site of events happening (ex: user logging in/out)
     */
    if (event?.data?.type === 'bv.embedConfig' && event.data.payload) {
      // Store the config in state and sessionStorage
      this.applicationStore.updateEmbedConfig({
        hideHeader: event.data.payload.hideHeader,
        hideBreadcrumbs: event.data.payload.hideBreadcrumbs,
        hideNavbar: event.data.payload.hideNavbar,
        hideSEOFooter: event.data.payload.hideSEOFooter,
        disableRouteChanges: event.data.payload.disableRouteChanges,
        routeChangeWhitelist: event.data.payload.routeChangeWhitelist,
      });

      if (parent) {
        // declare listeners which will be used to inform the embedding site of particular events
        this.initEmbedListeners();
      }
    }
  }

  private attachPrefetchLinks(): void {
    this.accountQuery.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe(isAuthenticated => {
      const href = '/my-bets/sports/open';
      if (isAuthenticated) {
        this.metaService.setPrefetchLink(href);
      } else {
        this.metaService.unsetPrefetchLink(href);
      }
    });
  }

  private initEmbedListeners(): void {
    this.accountQuery.isAuthenticated$
      .pipe(startWith(false), pairwise(), takeUntil(this.destroy$))
      .subscribe(([previousValue, newValue]) => {
        // notify parent when when login status switches from false->true or true->false
        if (previousValue !== newValue && (previousValue || newValue)) {
          parent.postMessage(
            {
              source: 'BetkingMobile',
              type: 'bv.embedEvent',
              payload: {
                key: 'authStatus',
                value: {
                  authenticated: newValue,
                  token: newValue ? this.accountQuery.accessToken : undefined,
                },
              },
            },
            '*'
          );
        }
      });
  }

  private createDataLayerEvent(): void {
    const event: DataLayerEvent = {
      event: 'user-data-init',
      siteLang: this.languageService.selectedLanguage.language,
      siteUX: this.dataLayerService.dataLayerSiteUX(),
      userAgent: navigator.userAgent,
    };

    // eslint-disable-next-line no-console
    console.log('UserAgent', event);

    // eslint-disable-next-line no-console
    console.log('UserAgent', navigator.userAgent);

    // eslint-disable-next-line no-console
    console.log('UserAgent', event.userAgent);

    // eslint-disable-next-line no-console
    console.log('UserAgent', event.userAgent.toString);

    if (this.accountQuery.isAuthenticated) {
      event.userID = this.accountQuery.userData.id;
      event.userType = this.accountQuery.dataLayerUserType;
      event.userBalance = this.accountQuery.userData.wallets[0].balance; // Main balance
      if (this.accountQuery.userData.parentId) {
        event.parentUserID = this.accountQuery.userData.parentId;
      }
    }

    this.dataLayerService.createDataLayerEvent(event);
  }

  private initialise(): void {
    // The languageService initializer was previously in the constructor not in ngOnInit.
    // This is a better place for it, but if it start throwing race conditions we should consider moving it back.
    this.languageService.initialize();
    this.geoAccessControlService.initialize();
    this.initializeMobilePlugins.initialiseMobileApp();
    this.applicationService.initialize();
    this.dynamicScriptLoaderService.initialise();
    this.loadingService.initialize();
    this.elasticService.initialize();
    this.geolocationRedirectService.initialize();
    this.productTypeService.initialize();
    this.evaluationService.initEvaluation();
    this.cashoutService.initPendingCashouts();
    this.accountService.getBankProfile().subscribe();
    this.metaService.initialize(this.appConfig.get('apiBaseUrl').cms, true, this.languageService.selectedLanguage.locale);
    this.shopOwnerService.initialize();
    this.couponReceiptService.initialise();
    this.accumulatorBonusService.getCMSContent();
    this.sportsOddsBoostService.retrieveOddsBoostInfoModalCMSContent();
    this.liveService.initialize();
    this.headerService.initialize();

    combineLatest([
      this.applicationQuery.isVirtualsInstant$,
      this.applicationQuery.isVirtualsScheduled$,
      this.applicationQuery.loaderState$,
    ])
      .pipe(debounceTime(0))
      .subscribe(([isInstant, isScheduled, loaderState]) => {
        // Initialize coupon only when loading state is false - when route and selected league are resolved
        if (!loaderState.show) {
          if (isScheduled) {
            this.virtualsCouponService.initialize();
          } else if (isInstant) {
            this.instantCouponService.initialize();
          } else {
            this.couponService.initialize();
          }
        }
      });
  }

  private setAffiliateCookieByConfig(): void {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('utm_source') && this.cookieService.getCookie('KM_AFF_TRK')) {
        this.cookieService.clearCookie('KM_AFF_TRK');
      }

      // checking if user navigated by internal campaign.
      if (urlParams.has('ic') && this.cookieService.getCookie('KM_AFF_TRK')) {
        this.cookieService.clearCookie('KM_AFF_TRK');
      } else if (urlParams.has('btag') && !urlParams.has('ic')) {
        const btagValue = urlParams.get('btag');

        // once cookie expires browser will remove cookie so if cookie not exist just create.
        if (this.appConfig.get('cookieFirstClick') && !this.cookieService.getCookie('KM_AFF_TRK')) {
          this.cookieService.setCookie('KM_AFF_TRK', btagValue, this.appConfig.get('affiliateCookieExpiry'), '/', 'None', true);
        }

        if (this.appConfig.get('cookieLastClick')) {
          if (this.cookieService.getCookie('KM_AFF_TRK')) {
            this.cookieService.clearCookie('KM_AFF_TRK');
          }

          this.cookieService.setCookie('KM_AFF_TRK', btagValue, this.appConfig.get('affiliateCookieExpiry'), '/', 'None', true);
        }
      }
    }
  }

  private setApmUserContext(): void {
    // update APM user context whenever the userData value gets updated
    this.accountQuery.userData$.pipe(takeUntil(this.destroy$)).subscribe(userData => {
      userData
        ? this.elasticService.setUserContext({
            id: userData.id,
            username: userData.username,
          })
        : this.elasticService.clearUserContext();
    });
  }
}
